// Hooks
import { useRef, useEffect, useReducer, createRef, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';

// Next
import Image from 'next/image';
import Link from 'next/link';

// Contentful
import { imageLoader } from '../../helpers/contentful';

// Analytics
import { analyticsClient } from '../../client/analytics';

// Components
import Author from '../Author/Author';

// Data Utils
import getHomeCasesData from '../../utils/data/getHomeCasesData';

// var for underline animation logic
let prevNavItem = 0;

// Case state reducer
const casesReducer = (state, action) => {
  // avoid no-case-declarations lint error
  const newState = { ...state };

  switch (action.type) {
    case 'UPDATE_CASE':
      return { ...state, active: action.index };
    case 'UPDATE_CASE_SCENARIO': {
      // Update scenario index
      const indexKey = newState.cases.findIndex((caseItem) => caseItem.index === state.active);
      newState.cases[indexKey].activeScenario = action.index;
      return { ...newState };
    }
    case 'UPDATE_ORDER':
      newState.cases.push(newState.cases.shift());
      return { ...newState };
    default:
      return state;
  }
};

const HomeCases = (props) => {
  // Set references for DOM elements
  const casesArea = useRef();
  const casesNavigation = useRef();
  const scenarioLinks = [];
  const isCampaign = props.campaign;

  // we use the useMemo hook to avoid parsing the props multiple times
  const stateData = useMemo(() => getHomeCasesData(props), []);
  const [casesState, dispatch] = useReducer(casesReducer, stateData); // eslint-disable-line no-unused-vars

  // Store different data elements in different variables
  const caseActive = casesState.cases.filter((caseItem) => caseItem.index === casesState.active)?.[0];
  const caseText = caseActive.text;
  const cases = casesState.cases;
  const caseScenarios = caseActive.scenarios;
  const caseActiveScenario = caseScenarios[caseActive.activeScenario];

  // intersection observer for cases area background color
  const [homeCases, inView] = useInView({
    rootMargin: '0px 0px -26.5% 0px',
  });

  // when in view change color of background
  useEffect(() => {
    if (inView) {
      casesArea.current.classList.add('is-visible');
    } else {
      casesArea.current.classList.remove('is-visible');
    }
  }, [inView]);

  // determine what the scale transform origin of the nav underline should be based on previous selected item
  const onSelectNavItem = (index) => {
    for (let i = 0; i < scenarioLinks.length; i += 1) {
      scenarioLinks[i].current.classList.remove('is-scalefromleft');
      scenarioLinks[i].current.classList.remove('is-scalefromright');

      if (i === index) {
        if (prevNavItem > index) {
          scenarioLinks[i].current.classList.add('is-scalefromright');
        } else {
          scenarioLinks[i].current.classList.add('is-scalefromleft');
        }
        scenarioLinks[i].current.classList.add('is-active');
      } else {
        if (index < i) {
          scenarioLinks[i].current.classList.add('is-scalefromleft');
        } else {
          scenarioLinks[i].current.classList.add('is-scalefromright');
        }
        scenarioLinks[i].current.classList.remove('is-active');
      }
    }
    prevNavItem = index;
  };

  // initial active item
  useEffect(() => {
    prevNavItem = caseActive.activeScenario;
    onSelectNavItem(caseActive.activeScenario);
  }, [caseActive]);

  return (
    <div className={`HomeCases ${isCampaign ? 'HomeCases--campaign' : ''}`} ref={homeCases}>
      <div className="HomeCases-inner">
        <div className="HomeCases-wrapper">
          <div className="HomeCases-text">
            <div className="HomeCases-textContainer">
              <Link href={caseActive.url}>
                <a className="HomeCases-textLink">
                  {caseActive.tag && <span className="HomeCases-tag">{caseActive.tag}</span>}
                  {(caseActiveScenario.title || caseText.title) && (
                    <h2 className="HomeCases-title">
                      <span className="HomeCases-titleLabel">{caseActiveScenario.title || caseText.title}</span>
                      <svg className="HomeCases-titleIcon">
                        <use xlinkHref="#chevron-right" />
                      </svg>
                    </h2>
                  )}
                  {caseActive.author && <Author {...caseActive.author} modifier="Author--homeCases" />}
                </a>
              </Link>
            </div>
          </div>

          <div className="HomeCases-cases" ref={casesArea}>
            <div className="HomeCases-navigation">
              <div className="HomeCases-caseNavigation" ref={casesNavigation}>
                {cases.map((item, index) => {
                  const active = casesState.active === item.index;
                  const styles = active ? 'HomeCases-caseLogo is-active' : 'HomeCases-caseLogo';
                  const logoActive = item.navigation[0];
                  const logoDefault = item.navigation[1];
                  return (
                    <div
                      key={`case-${item.slug}--${index}`}
                      className={styles}
                      onClick={() => {
                        analyticsClient.track('Case study customer clicked', {
                          caseSlug: item.slug,
                        });
                        dispatch({ type: 'UPDATE_CASE', index: item.index });
                      }}
                    >
                      <div className="HomeCases-logoImage HomeCases-logoImage--default">
                        <Image
                          sizes="300px"
                          layout="fill"
                          lazyBoundary="500px"
                          objectFit="contain"
                          objectPosition="center center"
                          loader={imageLoader}
                          alt={logoActive.fields.title}
                          src={`https:${logoActive.fields.file.url}`}
                        />
                      </div>
                      <div className="HomeCases-logoImage HomeCases-logoImage--active">
                        <Image
                          sizes="300px"
                          layout="fill"
                          lazyBoundary="500px"
                          objectFit="contain"
                          objectPosition="center center"
                          loader={imageLoader}
                          alt={logoDefault.fields.title}
                          src={`https:${logoDefault.fields.file.url}`}
                        />
                      </div>
                    </div>
                  );
                })}

                {cases.length > 2 && (
                  <span className="HomeCases-caseNav" onClick={() => dispatch({ type: 'UPDATE_ORDER' })}>
                    <svg className="HomeCases-caseNavArrow">
                      <use xlinkHref="#arrow-next" />
                    </svg>
                  </span>
                )}
              </div>
            </div>
            <div className="HomeCases-container">
              <div className="HomeCases-caseScenarios">
                {caseScenarios.map((scenario, index) => {
                  scenarioLinks[index] = createRef();
                  return (
                    <span
                      key={`scenario-${scenario.label}`}
                      className="HomeCases-scenarioLink"
                      ref={scenarioLinks[index]}
                      onClick={() => {
                        analyticsClient.track('Case study scenario clicked', {
                          scenario: scenario.label,
                          caseSlug: caseActive.slug,
                        });
                        dispatch({ type: 'UPDATE_CASE_SCENARIO', index });
                        onSelectNavItem(index);
                      }}
                    >
                      {scenario.label}
                    </span>
                  );
                })}
              </div>

              <div className="HomeCases-caseScreens">
                {caseScenarios.map((scenario, index) => {
                  const active = caseActive.activeScenario === index;
                  const styles = active ? 'HomeCases-screen is-active' : 'HomeCases-screen';
                  const defaultImage = scenario.images[scenario.images.length - 1];
                  return (
                    <div key={`screen-${scenario.label}`} className={styles}>
                      <Image
                        layout="fill"
                        lazyBoundary="500px"
                        objectFit="cover"
                        objectPosition="top left"
                        sizes="(min-width: 1440px) 885px,(min-width: 640px) 65vw, 100vw"
                        loader={imageLoader}
                        alt={defaultImage.fields.title}
                        src={`https:${defaultImage.fields.file.url}`}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeCases;
