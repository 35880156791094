import { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { gsap, Power3 } from 'gsap';
import { SplitText } from '../../libs/SplitText';

import Button from '../Button/Button';
import Pattern from '../Pattern/Pattern';
import RequestInvite from '../RequestInvite/RequestInvite';
import { getDomainWithoutSubdomain } from '../../helpers/url';

gsap.registerPlugin(SplitText);

let shuffledWords = [];

let inViewOffset = 75;

const HomeHeader = (props) => {
  const { title, campaign: isCampaign, noAnimation } = props;

  const refTitleContainer = useRef();
  const refTitle = useRef();
  const refPatternContainer = useRef();

  const tl = gsap.timeline();

  const [introFinished, setIntroFinished] = useState(false);

  useEffect(() => {
    if (noAnimation) {
      return () => null;
    }

    // set session storage
    sessionStorage.setItem('mutiny_home', true);

    // split refTitle in words
    // shuffle the words randomly
    shuffledWords = !noAnimation
      ? new SplitText(refTitle.current, {
          type: 'words',
        }).words
          .map((a) => [Math.random(), a])
          .sort((a, b) => a[0] - b[0])
          .map((a) => a[1])
      : [refTitle.current];

    // only run animation once per session
    if (sessionStorage.getItem('mutiny_home') !== 'true') {
      // start full intro animation
      tl.from(refTitleContainer.current, {
        duration: 0.5,
        scale: 2,
        ease: Power3.easeOut,
      })
        .to(shuffledWords, { duration: 0.4, color: '#250042', stagger: 0.04 }, '-=0.7')
        .to(shuffledWords, { duration: 0.2, color: '#3A0068', stagger: 0.04 }, '-=0.39')
        .to(
          shuffledWords,
          {
            duration: 0.3,
            color: '#AA00FF',
            onComplete: () => {
              setIntroFinished(true);
            },
            stagger: 0.04,
          },
          '-=0.19',
        )
        .to(shuffledWords, { duration: 0.6, color: '#CFF6FF', stagger: 0.04 }, '-=0.29')
        .to(refPatternContainer.current, { duration: 2, opacity: 1 }, '-=1.1');
    } else {
      // start simple intro animation
      tl.to(shuffledWords, {
        duration: 0.8,
        delay: 0.2,
        color: '#CFF6FF',
        onComplete: () => {
          setIntroFinished(true);
        },
        stagger: 0.04,
      }).to(refPatternContainer.current, { duration: 2, opacity: 1 }, '-=1.1');
    }

    return () => {
      tl.kill();
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      // used for rootMargin intersection observer
      inViewOffset = window.innerHeight * 0.75;
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // intersection observer
  const [refHomeHeader, inView] = useInView({
    rootMargin: `-${inViewOffset}px 0px 0px 0px`,
  });

  useEffect(() => {
    // scroll color change
    if (introFinished) {
      if (inView) {
        tl.to(shuffledWords, { duration: 0.4, css: { color: isCampaign ? '#FFFFFF' : '#CFF6FF' }, stagger: 0.04 });
      } else {
        tl.to(shuffledWords, { duration: 0.4, css: { color: '#AA00FF' }, stagger: 0.04 });
      }
    }
  }, [inView]);

  return (
    <div className={`HomeHeader ${isCampaign ? 'HomeHeader--campaign' : ''}`} ref={refHomeHeader}>
      <div className="HomeHeader-inner">
        <div className="HomeHeader-titleContainer" ref={refTitleContainer}>
          <div className="HomeHeader-titleWrapper">
            <h1 className={`HomeHeader-title ${isCampaign ? 'HomeHeader-title--abmCampaign' : ''}`} ref={refTitle}>
              {title}
            </h1>
          </div>
        </div>
        {!isCampaign ? (
          <div className="HomeHeader-requestInviteContainer">
            <RequestInvite
              name="home-page-hero"
              modifier="RequestInvite--homeHeader"
              defaultLabel="you@email.com"
              buttonLabel="See Mutiny on your site"
              buttonURL={`https://demo.${getDomainWithoutSubdomain()}/`}
              textlink={{ label: 'Or talk to our team', url: '/request-invite' }}
              source="home-header"
            />
          </div>
        ) : (
          <div className="HomeHeader-scheduleDemoContainer">
            <Button label="Schedule Demo" url="#demo" modifier="Button--whiteFill" />
          </div>
        )}
      </div>
      <div className="HomeHeader-patternContainer" ref={refPatternContainer}>
        <Pattern />
      </div>
    </div>
  );
};

export default HomeHeader;
